<template>
  <div class="forgot-password col-pc-12">
    <div class="box col-pc-4 col-smobile-12 uk-margin-auto">
      <div class="top">
        <div class="flexbox col-f">
          <div class="return col-all-6">
            <router-link class="secondary" :to="{ name: 'login' }">{{
              $t('forgot-password.return-login')
            }}</router-link>
          </div>
          <div class="help col-all-6">
            <a class="secondary" target="_blank" href="//leafcad.com/support">{{ $t('actions.help') }}</a>
          </div>
        </div>
      </div>
      <div class="middle col-pc-10 col-smobile-12 col-mobile-10 col-margin-auto">
        <div class="logo">
          <img
            class="uk-margin-small-top uk-margin-small-bottom"
            src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png"
            style="max-width: 120px;"
            alt="logo"
          />
        </div>
        <form class="form col-pc-12 col-mobile-12">
          <alerts-display :errors="errors" />
          <div class="email" v-if="!emailSent && !passwordReset && !$route.params.token">
            <p class="primary">{{ $t('forgot-password.enter-email-used-for-account') }}</p>
            <CustomInput
              class="custom-input primary col-all-12 col-mdmobile-8 col-smobile-8"
              required="required"
              type="email"
              icon="mail"
              :placeholder="$t('forgot-password.email')"
              v-model.trim="$v.email.$model"
              :model="$v.email"
              :error="fieldsErrors.email"
            ></CustomInput>
            <VueReCaptcha sitekey="6LdmJ6EUAAAAAHsxB2RbCShfFn54eJ-cYMbtoCAK">
              <button
                class="primary-full col-all-12 col-mdmobile-8 col-smobile-8"
                :class="{ disable: isLoading, spinner: isLoading }"
                @click="sendEmail()"
              >
                {{ $t('forgot-password.send-email') }}
              </button>
            </VueReCaptcha>
          </div>
          <div class="sended" v-if="emailSent && !passwordReset && !$route.params.token">
            <p class="primary">
              {{ $t('forgot-password.email-sent-to') }}
              <span>{{ email }}</span>
            </p>
            <p class="primary">{{ $t('forgot-password.check-your-spam') }}</p>
          </div>
          <div class="reset form" v-if="!passwordReset && $route.params.token">
            <p class="primary">{{ $t('forgot-password.enter-your-new-password') }} *</p>
            <CustomInput
              class="custom-input primary col-all-12 col-mdmobile-8 col-smobile-8"
              required="required"
              type="password"
              icon="lock"
              autocomplete="new-password"
              :placeholder="$t('forgot-password.password')"
              v-model.trim="$v.password.$model"
              :model="$v.password"
              :error="fieldsErrors.password"
            ></CustomInput>
            <p class="primary">{{ $t('forgot-password.retype-your-new-password') }} *</p>
            <CustomInput
              class="custom-input primary col-all-12 col-mdmobile-8 col-smobile-8"
              required="required"
              type="password"
              icon="lock"
              autocomplete="new-password"
              :placeholder="$t('forgot-password.retype-password')"
              v-model.trim="$v.retypedPassword.$model"
              :model="$v.retypedPassword"
              :error="fieldsErrors.retypedPassword"
            ></CustomInput>
            <button
              class="primary-full col-all-12 col-mdmobile-8 col-smobile-8"
              :class="{ disable: isLoading, spinner: isLoading }"
              @click="resetPassword()"
            >
              {{ $t('forgot-password.confirm') }}
            </button>
          </div>
          <div class="sended middle" v-if="passwordReset">
            <p class="primary">{{ $t('forgot-password.password-has-been-reset') }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/login/forgot-password.less"></style>
<style lang="less" scoped src="@/assets/less/login/register.less"></style>

<script>
import validationMessage from '@/validationMapping'
import { email, requiredIf, sameAs } from 'vuelidate/lib/validators'
import CustomInput from '@/components/CustomInput.vue'
import VueReCaptcha from 'vue-recaptcha'

export default {
  name: 'forgot-password',
  components: {
    CustomInput,
    VueReCaptcha
  },
  data: function() {
    return {
      errors: [],
      isLoading: false,
      fieldsErrors: {},
      email: '',
      emailSent: false,
      password: '',
      retypedPassword: '',
      passwordReset: false
    }
  },
  validations: {
    email: {
      required: requiredIf(function() {
        return !this.$route.params.token
      }),
      email
    },
    password: {
      required: requiredIf(function() {
        return this.$route.params.token
      })
    },
    retypedPassword: {
      required: requiredIf(function() {
        return this.$route.params.token
      }),
      sameAs: sameAs('password')
    }
  },
  methods: {
    sendEmail: function() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.errors = ['FIX_ERRORS']
        this.fieldsErrors = {
          email: validationMessage(this.$v.email)
        }
        this.isLoading = false
        return
      }

      this.$store
        .dispatch('auth/sendPasswordResetToken', { email: this.email })
        .then(response => {
          this.emailSent = true
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false

          error.graphQLErrors.map(({ extensions }) => {
            this.errors = Object.values(extensions)
            this.fieldsErrors = {
              email: true
            }
          })
        })
    },
    resetPassword: function() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.errors = ['FIX_ERRORS']
        this.fieldsErrors = {
          password: validationMessage(this.$v.password),
          retypedPassword: validationMessage(this.$v.retypedPassword)
        }
        this.isLoading = false
        return
      }

      this.$store
        .dispatch('auth/resetPassword', {
          password: this.password,
          token: this.$route.params.token
        })
        .then(response => {
          this.passwordReset = true
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false

          error.graphQLErrors.map(({ extensions }) => {
            this.errors = Object.values(extensions)
            this.fieldsErrors = {
              password: true,
              retypedPassword: true
            }
          })
        })
    },
    recaptcha() {
      this.$recaptcha('login').then(token => {
        console.log(token) // Will print the token
      })
    }
  }
}
</script>
